import React from "react"
import { functions, db, auth } from "../firebase"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import getUserData from "../scripts/getUserData"
import AsyncButton from "../components/utils/AsyncButton"
import TitleLine from "../components/utils/TitleLine"
import Login from "../pages/Login"
import { Container, Row, Col } from "react-bootstrap"
import InputCreator from "../components/styledInputs/InputCreator"
import dots from "../images/bgs/dots.svg"


class FeedbackForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      userData: null,
      email: "",
      contents: "",
    }
  }

  sendMessage = reset => {
    require("firebase/functions")
    let sendFeedbackEmail = functions
      .httpsCallable("callables-sendFeedbackEmail")

    sendFeedbackEmail({
      info: this.state.contents,
      email: this.state.email,
      timeStamp: Date.now()
        .toString()
        .substring(4, 16),
    })
      .then(() => {
        reset("Feedback Sent!")
      })
      .catch(error => {
        console.log(error)
        reset("An error occured. Try again?")
      })
  }

  handleForm = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({
          authenticated: true,
          email: user.email,
          name: user.displayName,
        })
      }
    })
  }

  render() {
    const regularInput = this.state.authenticated
      ? ["Type Your Message Here"]
      : ["Email", "Type Your Message Here"]
    const inputNames = this.state.authenticated
      ? ["contents"]
      : ["email", "contents"]
    const type = this.state.authenticated ? ["textarea"] : ["text", "textarea"]
    const struct = regularInput.map(val => 1)
    const rows = regularInput.map((val, ind) => (ind === 1 ? 4 : 1))
    const errors = regularInput.map(val => false)

    return (
      <Layout image={dots}>
        <SEO title="FeedBack Form" />
        <Container className="topSpacing">
          <TitleLine
            title="Feedback"
            description="Please Enter any feedback you would like to give the team!"
          />
          <ul>
            <Row className="sidesPadding">
              <InputCreator
                titles={regularInput}
                errors={errors}
                types={type}
                names={inputNames}
                struct={struct}
                rows={rows}
                callback={this.handleForm}
                values={this.state}
              />
            </Row>
          </ul>
          <Row className="center sidesPadding justify-content-center">
            <AsyncButton
              buttonTexts={[
                "Send Message",
                "Sending...",
                "Invalid Email. Try Again?",
                "An error occured. Try again?",
                "Message Sent!",
              ]}
              buttonVariants={[
                "primary",
                "primary",
                "danger",
                "danger",
                "success",
              ]}
              callback={this.sendMessage}
              classname="buttonTop"
            />
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default FeedbackForm
