import React from "react" // import react
import { Col, Container, Row } from "react-bootstrap"
import "../../styles/About.css"

function TitleLine(props) {
  return (
    <Container className="center">
      <Row className="center justify-content-center">
        <Col className="center justify-content-center">
          <>
            <Row className="justify-content-center">
              <span class="title studentTitle">{props.title}</span>
            </Row>
            <Row className="justify-content-center">
              <hr class="center"></hr>
            </Row>
          </>
        </Col>
      </Row>
      <Row className="center justify-content-center">
        <Col className="center justify-content-center">
          <span2 class="description center">{props.description}</span2>
        </Col>
      </Row>
      <br></br>
      {props.signUp === true ? (
        props.title === "Student Login" ? (
          <a href="create-student-account" class="description center">
            Dont have an account yet? Sign up here.
          </a>
        ) : (
          <a href="create-teacher-account" class="description center">
            Dont have an account yet? Sign up here.
          </a>
        )
      ) : (
        <div></div>
      )}
    </Container>
  )
}

export default TitleLine
