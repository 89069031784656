import React from "react"
import { Button } from "antd"
import "antd/dist/antd.css" // or 'antd/dist/antd.less'

class AsyncButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      buttonText: this.props.buttonTexts[0],
      buttonVariant: this.props.buttonVariants[0],
    }
  }

  

  reset = isCompleted => {
    let offset = Math.abs(3 - this.props.buttonTexts.length)
    let error = this.props.buttonTexts.length === 4
    if (isCompleted) {
      this.setState({
        isLoading: false,
        buttonText: this.props.buttonTexts[2 + offset],
        buttonVariant: this.props.buttonVariants[2 + offset],
      })
    } else {
      if (error) {
        this.setState({
          isLoading: false,
          buttonText: this.props.buttonTexts[2],
          buttonVariant: this.props.buttonVariants[2],
        })
      } else {
        this.setState({
          isLoading: false,
          buttonText: this.props.buttonTexts[0],
          buttonVariant: this.props.buttonVariants[0],
        })
      }
    }
  }

  handleLoading = () => {
    this.setState(
      {
        isLoading: true,
        buttonText: this.props.buttonTexts[1],
        buttonVariant: this.props.buttonVariants[1],
      },
      () => {
        setTimeout(
          function() {
            this.props.callback(this.reset)
          }.bind(this),
          750
        )
      }
    )
  }

  render() {
    return (
      <>
        <Button
          type={this.state.buttonVariant}
          loading={this.state.isLoading}
          onClick={this.handleLoading}
          size="large"
        >
          {this.state.buttonText}
        </Button>
      </>
    )
  }
}

export default AsyncButton
