import { navigate } from "gatsby"
import React from "react"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import Student from "../images/student.png"
import Teacher from "../images/teacher.png"
import "../styles/GlobalStyles.css"
import spring from "../images/bgs/spring.svg"


class Login extends React.Component {

  render() {
    return (
      <Layout image={spring} full>
        <SEO title="Login" />
        <Container
          className="justify-content-center"
          style={{ marginTop: "70px", marginBottom: "70px" }}
        >
          <Row className="justify-content-center">
            <Col
              className="globalPackage displayFlex"
              style={{ marginTop: "25px" }}
            >
              <Card style={{ width: "18rem", height: "auto" }}>
                <Card.Img variant="top" src={Student} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    Student Login
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    Continue serving the community and gaining volunteer hours
                  </Card.Text>
                  <Row
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <Button
                      variant="info"
                      onClick={() => navigate("/student-login/")}
                    >
                      <b>Go</b>
                    </Button>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col
              className="globalPackage displayFlex"
              style={{ marginTop: "25px" }}
            >
              <Card style={{ width: "18rem", height: "auto" }}>
                <Card.Img variant="top" src={Teacher} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    Teacher Login
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    Continue posting projects and saving time
                  </Card.Text>
                  <Row
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <Button
                      variant="info"
                      onClick={() => navigate("/teacher-login/")}
                    >
                      <b>Go</b>
                    </Button>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Login
